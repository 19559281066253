<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
              <el-select clearable style="margin:0 15px" v-model="area_id" @clear="clearOption" filterable >
                <el-option
                    v-for="item11 in region"
                    :label="item11.area_name"
                    :key="item11.name"
                    :value="item11.area_id"
                    
                    >
                </el-option>
              </el-select>  
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>  
    </div>
    <el-table  height="calc(100vh - 255px)" :data="tableData" style="width: 100%;">
      <el-table-column  align="center" type="index" width="55" label="#" >
      </el-table-column>
      <el-table-column prop="icon" width="80" label="图标">
        <template #default="scope">
          <div style="height: 100%;display: flex;align-items:center;">
            <el-image
                v-if="scope.row.icon"
                style="width: 50px; height: 50px"
                :src="scope.row.icon"
                :preview-src-list="[scope.row.icon]">
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="title"  label="模块标题">
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="area_name" min-width="157" label="地区" />
      <el-table-column prop="type"  label="模块类型">
        <template #default="scope">
          <span v-if="scope.row.type==0">轮播图</span>
          <span v-if="scope.row.type==1">导航栏</span>
        </template>
      </el-table-column>
      <el-table-column prop="tip"  label="tip描述">
      </el-table-column>
      <el-table-column prop="subtitle"  label="副标题">
      </el-table-column>
      <el-table-column prop="sequence" label="序号">
      </el-table-column>
<!--      show-overflow-tooltip-->
      <el-table-column label="操作" width="195">
        <template #header>
          <el-button  size="mini" @click="add()" type="primary" style="margin:10px 0;">新增</el-button>
        </template>
        <template #default="scope">
          <div style="margin: 5px 0">
            <el-button-group>
              <el-button size="mini" type="primary"  @click="addmodow(scope.row)">模块内容</el-button>
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
              <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
            </el-button-group>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40,50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <el-drawer
        title="新增/修改"
        v-model="drawer"
        size="40%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="80px"
          class="demo-ruleForm">
        <el-form-item  label="图标" prop="logo">
          <el-upload
              style=" width: 100px;height: 100px"
              class="avatar-uploader"
              :action="UpURL"
              :show-file-list="false"
              :http-request="httpRequest"
              :before-upload="beforeAvatarUpload"
          >
            <img v-if="ruleForm.icon" :src="ruleForm.icon" class="avatar" />
            <i v-else  class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <p style="color: #999999"><span style="font-size: 16px" class="el-icon-info"> </span>图片尺寸 宽 200 X 高 200</p>

        </el-form-item>
        <el-form-item label="模块标题" prop="title">
          <el-input v-model="ruleForm.title"></el-input>
        </el-form-item>

        <el-form-item label="模块类型" prop="type">
          <el-select style="width:100%" v-model="ruleForm.type" >
            <el-option
                v-for="item in usertype"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="地区" prop="area_id">
                <el-select clearable  style="width:100%" v-model="ruleForm.area_id" >
                <el-option
                    v-for="item in region"
                    :key="item.id"
                    :label="item.area_name"
                    :value="item.area_id">
                </el-option>
              </el-select>
            </el-form-item>

        <el-form-item label="序号" prop="">
          <el-input-number style="width:100%" v-model="ruleForm.sequence"></el-input-number>
        </el-form-item>
        <el-form-item label="副标题" prop="">
          <el-input v-model="ruleForm.subtitle"></el-input>
        </el-form-item>
        <el-form-item label="tip描述" prop="">
          <el-input type="textarea" :rows="5" v-model="ruleForm.tip"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
          <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
    <el-drawer
        title="首页模块内容"
        v-model="dmodowdrawer"
        size="50%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <Module  :nowData="rowData"></Module>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import * as axios from "@/api/homepageApi"
import {getbrandselect, getIMG, getUQIMGurl, postCateTree} from "@/api/common";
import Module from "../components/homepageChild/modulecontent"
import {addDq} from "@/api/Api.js"
//本页面接口
export default {
  name: 'brand',
  components:{
    Module,
  },
  data(){
    return{
      UpURL:'',
      dmodowdrawer:false,
      login:false,
      drawer:false,
      category:[],
      tableData:[],
      page:1,
      limit:10,
      total:0,
      area_id:'',
      keywords:'',
      ruleForm:{
        title:'',
        type:'',
        sequence:'',
        icon:'',
        tip:'',
        subtitle:'',
        area_id:''
      },
      rowData:{
        app_home_id:'',
        type:'',
        modo:1,
      },
      rules:{
        title: [
          { required: true, message: '请填写名称', trigger: 'blue' },
        ],
        type: [
          { required: true, message: '请选择', trigger: 'blue' },
        ],
         area_id: [
          { required: true, message: '请选择', trigger: 'blue' },
        ],
        
      },
      usertype:[
        {
          id:0,
          name:'轮播图',
        },
        {
          id:1,
          name:'导航栏',
        },
      ]
    }
  },
  created() {
    this.onselect()
    this.keyupSubmit()
    this.getaddDq()
    // this.onTable()
  },

  methods:{
    //LOGO上传图片方法
    httpRequest(options) {
      this.login=true
      // console.log('options',options.file,options.file.type)
      return new Promise((resolve, reject) => {
        let src=options.file.type
        getUQIMGurl({bucket:'product',ext:'.'+src.substr(6)}).then((res)=>{
          // console.log('获取URL',res)
          if(res.code==0){
            this.UpURL=res.data.upload_url
            let url=res.data.preview_url
            // console.log(this.UpURL)
            getIMG(options.file,this.UpURL,options.file.type).then((res)=>{
              resolve(res)
              this.ruleForm.icon=url
              this.login=false
            }).catch((err)=>{
              reject(err)
              this.login=false
            })
          }else {
            this.$message.error('获取上传地址失败！！')
            this.login=false
          }
        })

      })
    },
    //
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'||
          file.type === "image/png" ||
          file.type === "image/jpg"||
          file.type === "image/gif";
      // const isLt2M = file.size / 1024 / 1024 < 4
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG、JPEG、PNG、 格式!')
      }
      // if (!isLt2M) {
      // this.$message.error('上传头像图片大小不能超过 4MB!')
      // }
      return isJPG
      // && isLt2M
    },
    /*图片上传*/
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    onselect(){
      postCateTree('').then((res)=>{
        console.log('getCateTree',res)
        this.category=res.data
      }).catch((err)=>{

      })
      getbrandselect('').then((res)=>{
        console.log('getbrandselect',res)
        this.brand=res.data.data
      }).catch((err)=>{
      })
    },
    addmodow(row){
      this.rowData.app_home_id=row.id
      this.rowData.type=row.type
      this.dmodowdrawer=true
    },
    postcategory(){
      console.log(123)
      this.$refs.cascaderHandle.dropDownVisible = false
    },
    onTable(areaId){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
        area_id:areaId?areaId:this.area_id
      }
      axios.getmasterhomeData(form).then((res)=>{
        this.tableData=res.data.data
        this.total=res.data.total
      }).catch((err)=>{

      })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    add(){
      this.ruleForm= {
        title:'',
        type:'',
        sequence:0,
        icon:'',
        tip:'',
        subtitle:'',
      }
      this.drawer=true
    },
    upData(row){
      this.ruleForm.id=row.id
      this.ruleForm.title=row.title
      this.ruleForm.type=row.type
      this.ruleForm.sequence=row.sequence
      this.ruleForm.icon=row.icon
      this.ruleForm.tip=row.tip
      this.ruleForm.subtitle=row.subtitle
      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.postaddmasterhome(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                  that.login=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.posteditmasterhome(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }


        } else {
          this.login=false
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postdelmasterhome({id:row.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },
  // 获取城市地区列表数据
    async getaddDq(){
      const {data:res} = await addDq()
      this.region = res
      this.onTable(this.region[0].area_id)
    },
    clearOption(){
      this.area_id = this.region[0].area_id
      this.onTable()
    }
  }
}
</script>
<style lang="less" >
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
  padding: 0;
}
</style>